import React from 'react';

import loadable from '@loadable/component';
import { NoSsr } from '@mui/base';

import { makeStyles } from 'tss-react/mui';

import { AppSliderSkeleton } from './AppSliderSkeleton';
import { AppSliderProps } from './types';

export const LoadableSwiperSlider = loadable(
  () => import(/* webpackChunkName: "SwiperSlider" */ './SwiperSlider'),
);

const useStyles = makeStyles()(() => ({
  sliderContainer: {
    /**
     * - The slider is loaded client-side using a NoSSR component, which can cause a visual flicker
     *   during the transition from the placeholder to the rendered component.
     * - To prevent a collapse in height, a CSS custom property (`--container-height`) is used to
     *   dynamically set the `minHeight` based on the placeholder's height.
     */
    '--container-height': '',
    minHeight: 'var(--container-height)',
    position: 'relative',
  },
}));

export const AppSlider = ({
  fallback = <AppSliderSkeleton />,
  'data-test-locator': dataTestId,
  ...rest
}: AppSliderProps) => {
  const { classes } = useStyles();
  const containerRef = React.useRef<HTMLDivElement>(null);
  const containerHeightRef = React.useRef<number | null>(null);

  /**
   * Dynamically adjusts the container height after the first render.
   *
   * - Checks the height of the container element on the initial render.
   * - If the height is available and hasn't been set yet (`containerHeightRef.current` is null),
   *   it updates the custom CSS property (`--container-height`) with the measured height.
   * - This ensures that the container maintains a consistent height, matching the rendered placeholder.
   */
  React.useEffect(() => {
    if (containerRef.current && containerHeightRef.current === null) {
      const height = containerRef.current.offsetHeight;
      if (height) {
        containerHeightRef.current = height;
        containerRef.current.style.setProperty(
          '--container-height',
          `${height}px`,
        );
      }
    }
  }, []);

  return (
    <div
      className={classes.sliderContainer}
      ref={containerRef}
      data-test-locator={dataTestId}
    >
      <NoSsr fallback={fallback}>
        <LoadableSwiperSlider
          {...rest}
          data-test-locator="LoadableSwiperSlider"
        />
      </NoSsr>
    </div>
  );
};
