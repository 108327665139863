import React from 'react';

import { UnitType } from '@ha/api/v2/types/inventory/UnitType';

import { normalizeApiV2Listing } from 'ha/models/Listing/normalizeApiV2Listing';
import { NormalizedAlgoliaListing } from 'ha/models/Listing/types';
import { RudderstackEventNames } from 'ha/modules/Analytics/constants';
import { useTrackEvent } from 'ha/modules/Analytics/helpers/TrackEvent';

import { ListingCard } from 'ha/components/Redesign/ListingCard';
import { usePage } from 'ha/modules/Page/contexts';
import { useRecentlyViewedListings } from 'ha/modules/RecentlyViewed';

import { ListingsSlider } from '../../../../modules/ListingsSlider';

export const RecentlyViewedListingsSlider = () => {
  const trackEvent = useTrackEvent();
  const { category } = usePage();
  const { getRecentlyViewedListings } = useRecentlyViewedListings();
  const [recentlyViewedListings, setRecentlyViewedListings] = React.useState<
    UnitType[]
  >([]);

  const fetchListings = React.useCallback(async () => {
    const listings = await getRecentlyViewedListings();
    setRecentlyViewedListings(listings);
  }, [getRecentlyViewedListings]);

  React.useEffect(() => {
    fetchListings();
  }, [fetchListings]);

  const handleCardClick = React.useCallback(
    (listing: NormalizedAlgoliaListing) => {
      trackEvent(RudderstackEventNames.ListingCardClicked, {
        page: category,
        component: 'Recently Viewed',
        listingCity: listing.city,
        listingCountry: listing.country,
      });
    },
    [],
  );

  const getSliderItems = () => {
    return recentlyViewedListings.map(listing => {
      const normalizedListing = normalizeApiV2Listing(listing);
      return {
        key: listing.id,
        element: (
          <ListingCard
            key={listing.id}
            room={normalizedListing}
            withFavorites
            onCardClick={() => handleCardClick(normalizedListing)}
          />
        ),
      };
    });
  };

  return (
    <ListingsSlider
      sliderItems={getSliderItems()}
      data-test-locator="RecentlyViewedListingsSlider"
    />
  );
};
