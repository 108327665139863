import isNil from 'lodash-es/isNil';
import { AnyAction, Dispatch } from 'redux';

import { normalizeDate } from '@ha/date';
import { buildParams } from '@ha/search-query';

import { DEFAULT_SEARCH_FLEXIBILITY } from 'ha/constants/FlexibleSearchDates';

import { reportError } from 'ha/helpers/bugReporter/reportError';
import { UrlResolver } from 'ha/helpers/UrlResolver';
import { AppServices } from 'ha/services/getAppServices';

import { send as sendAlert } from 'ha/modules/AlertBar/actions';
import { AlertType, AlertDismissAfter } from 'ha/modules/AlertBar/types';

import { FormValues } from '../components/SearchBar/types';
import { ErrorMessages } from '../constants';

const submitSearch =
  ({
    formValues,
    lang,
    urlResolver,
  }: {
    formValues: FormValues;
    lang: string;
    urlResolver: UrlResolver;
  }) =>
  (dispatch: Dispatch<AnyAction>, __: () => void, { apiV2 }: AppServices) =>
    apiV2
      .searchCity({ query: formValues.place, languages: [lang] })
      .then(({ data: city }) => {
        const { cityName, countryName } = city[lang];
        const nextUrl = `${urlResolver.getSearchUrl(cityName, countryName)}`;

        const { dateRange } = formValues;

        const params = buildParams({
          startDate: normalizeDate(dateRange.startDate) || null,
          endDate: normalizeDate(dateRange.endDate) || null,
          flexDays:
            isNil(dateRange?.flexDays) ||
            dateRange?.flexDays === DEFAULT_SEARCH_FLEXIBILITY.Search
              ? null
              : dateRange?.flexDays,
        });

        const nextLocation = `${nextUrl}${params}`;

        window.location.assign(nextLocation);
      })
      .catch(error => {
        if (error.status === 404) {
          dispatch(
            sendAlert({
              text: ErrorMessages.SEARCH_NOT_FOUND,
              kind: AlertType.Warning,
              dismissAfter: AlertDismissAfter.Default,
            }),
          );
        } else {
          reportError('Search listings onSubmitFail', { metaData: { error } });
        }
      });

export { submitSearch };
