export enum ListingCardLocations {
  ldp = 'LDP',
  myFavorites = 'myFavorites',
  search = 'search',
  map = 'map',
  ldpSimilarListings = 'ldpSimilarListings',
  talkSimilarListings = 'talkSimilarListings',
  unspecified = 'unspecified',
  contactAdvertiser = 'contactAdvertiser',
}
